<template>
  <div id="app" data-app>
    <div class="row col-xl-12 mx-0 px-0">
      <!--start::TOPFORM-->
      <Topform
        ref="survey-topform"
        :managementType="managementType"
        :status="calculatedStatus"
        pageType="survey"
        :name="activityInfo.title"
        :errors="activityErrors"
        :submissionStates="submissionStates"
        parentPage="/project/activities/view"
        :tabs="computedTabs"
        :validation="computedValidation"
        :loadingComplete="loadingComplete"
        :additionalActions="computedActions"
        @clear_errors="clearErrors()"
        @asset_reservation_created="form.stimuli.push($event)"
        @asset_reservation_deleted="
          form.stimuli = form.stimuli.filter(function (stim) {
            return stim !== $event;
          })
        "
        @remove_project_asset="form.stimuli_remove.push($event)"
        @start_date_updated="form.starts_on = $event"
        @end_date_updated="form.ends_on = $event"
        @initial_notification_period_changed="initialNotificationPeriodChanged"
        @notification_repeat_option_changed="notificationRepeatOptionChanged"
        @repeat_notification_period_changed="repeatNotificationPeriodChanged"
        @clear_existing_data="clearData"
        @submit="onSubmit"
        @submit_then_sequence="onSubmitThenSequence"
      >
      </Topform>
      <!--end::TOPFORM-->
      <div class="col-xl-4">
        <div class="card card-custom gutter-b">
          <div class="card-body">
            <b-spinner
              v-if="loadingComplete == false"
              variant="primary"
              label="Spinning"
              class="card-loader"
            >
            </b-spinner>
            <div v-if="loadingComplete && !error.active">
              <h6 class="mb-2">
                {{ $t("ACTIVITY.MANAGE.HELPER_SEGMENT_TITLE") }}
              </h6>
              <p class="mb-10">
                {{ $t("ACTIVITY.MANAGE.HELPER_SEGMENT_TEXT") }}
              </p>
              <div class="row">
                <!-- Here be dragons - submissionStates manually set to false for now -->
                <InputAdvancedSegmentSelect
                  id="input-segments"
                  class="px-5 mb-5"
                  :submissionStates="{ submitting: false }"
                  children_key="project_segments"
                  label_key="name"
                  :options="projectSegmentations"
                  :initialSelection="initial_selection"
                  :sequenced="
                    isSequencedActivity ||
                    isDuplicateSequencedActivity ||
                    (isEditActivity && activityInfo.sequence_parent != null)
                  "
                  @children_changed="form.segments = $event"
                  @clear_segments="form.segments = []"
                  @initial_selection_changed="initial_selection = $event"
                >
                </InputAdvancedSegmentSelect>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-8">
        <b-modal
          ref="sequence-modal"
          size="xl"
          hide-footer
          :title="$t('TABLES.ACTIVITY_MESSAGES.SEQUENCE_MODAL')"
        >
          <div
            id="activity_sequence_selector"
            class="btn-group btn-group-sm w-100"
            role="group"
            style="box-shadow: none !important"
          >
            <template v-for="(systemActivityType, i) in systemActivityTypes">
              <a
                v-if="systemActivityType.can_sequence"
                href="javascript:void(0)"
                @click="
                  onSubmit(
                    'add_' +
                      systemActivityType.name.toLowerCase().split(' ').join('_')
                  )
                "
                :key="i"
                class="btn btn-outline-secondary bg-white px-3 py-5"
                style="border: none !important"
                v-b-tooltip.hover.bottom
                :title="
                  $t('NAVIGATION.ACTIVITY_DESCRIPTION.' + ([8,10].includes(systemActivityType.id) ? 8 : systemActivityType.id))
                "
              >
                <i
                  :class="getSystemActivityTypeIcon(systemActivityType)"
                  class="text-muted px-0 mb-3"
                >
                </i>
                <p class="font-size-sm mb-0">
                  {{ $t("NAVIGATION.ADD_ACTIVITY." + ([8,10].includes(systemActivityType.id) ? 8 : systemActivityType.id)) }}
                </p>
              </a>
            </template>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import {
  required,
  between,
  requiredIf,
  requiredUnless,
} from "vuelidate/lib/validators";
import SystemIconHelper from "@/modules/together-helpers/helpers/SystemIconHelper";
import ManageActivityHelper from "@/helpers/ManageActivityHelper";
import InputAdvancedSegmentSelect from "@/view/components/form-inputs/AdvancedSegmentSelect";
import PageLoader from "@/core/classes/PageLoader.js";
import Toast from "@/core/classes/Toast.js";

//Vuex Imports
import {
  CREATE_PROJECT_ACTIVITY,
  UPDATE_PROJECT_ACTIVITY,
  CLEAR_ACTIVITY_ERRORS,
  POPULATE_ACTIVITY_INFO,
  POPULATE_PARENT_ACTIVITY_INFO,
  CLEAR_ACTIVITY_INFO,
} from "@/core/services/store/activity/activity.module";
import {
  CLEAR_SURVEY_QUESTION_ERRORS,
  POPULATE_SURVEY_QUESTION_LIST,
  CLEAR_SURVEY_QUESTION_LIST,
} from "@/core/services/store/activity/survey_question.module";
import {
  CLEAR_SURVEY_QUESTION_CHOICE_ERRORS,
  POPULATE_SURVEY_QUESTION_CHOICE_LIST,
  CLEAR_SURVEY_QUESTION_CHOICE_LIST,
} from "@/core/services/store/activity/survey_question_choice.module";
import { POPULATE_PROJECT_SEGMENTATION_LIST } from "@/core/services/store/project/project_segmentations.module";
import { POPULATE_PROJECT_ROOM_SETUP_LIST } from "@/core/services/store/project/project_room_setups.module";

//Topform Imports
import TopformConfig from "@/core/config/topform/ManageSurvey.js";

export default {
  mixins: [validationMixin],

  name: "ManageSurvey",

  data() {
    return {
      selected_notification_period: { name: "1 day", value: 1 },
      selected_repeat_notification_period: { name: "1 day", value: 1 },
      selected_repeat_option: { name: "once", value: 0 },
      notification_toggle: 0,
      repeat_notification_toggle: 0,
      sequence_option_id: 1,
      initial_selection: [],
      error: {
        active: false,
        message: "",
      },
      pageLoader: new PageLoader(2),
      toast: new Toast(),
      submissionStates: {
        submitting: false,
      },
      form: {
        published: 1,
        featured: 0,
        project_room_id: null,
        starts_on: null,
        ends_on: null,
        notifications: {
          1: {
            data: {
              initial_notification: 1,
              keep_repeating: 0,
              repeat_frequency: 1,
            },
          },
        },
        system_social_setting_id: 3,
        system_social_bias_id: 1,
        system_activity_type_id: 4,
        title: null,
        summary: null,
        introduction: null,
        stimuli: [],
        stimuli_remove: [],
        settings: {
          // This is randomise questions toggle
          3: {
            data: {
              value: 0,
            },
          },
          // This is randomise pages toggle
          4: {
            data: {
              value: 0,
            },
          },
        },
        segments: [],
        sequence_parent: null,
      },
      form_reset: {},
      page_length: "All",
      page: 1,
    };
  },

  validations: {
    notification_toggle: {
      required,
    },
    repeat_notification_toggle: {
      required,
    },
    sequence_option_id: {
      required: requiredIf(function () {
        return this.isSequencedActivity;
      }),
    },
    form: {
      published: {
        required,
      },
      featured: {
        required,
      },
      notifications: {
        1: {
          data: {
            initial_notification: {
              required: requiredIf(function () {
                return this.isInitialNotificationToggled;
              }),
              between: between(1, 7),
            },
            keep_repeating: {
              required: requiredIf(function () {
                return this.isRepeatNotificationToggled;
              }),
            },
            repeat_frequency: {
              required: requiredIf(function () {
                return this.isRepeatNotificationToggled;
              }),
              between: between(1, 7),
            },
          },
        },
      },
      project_room_id: {},
      starts_on: {
        required: requiredIf(function () {
          return this.isScheduledActivity;
        }),
        maxValue(val, { ends_on }) {
          return new Date(val) < new Date(ends_on);
        },
      },
      ends_on: {
        required: requiredUnless(function () {
          return this.isSequencedActivity;
        }),
      },
      system_social_setting_id: {
        required,
      },
      system_social_bias_id: {
        required,
      },
      system_activity_type_id: {
        required,
      },
      title: {
        required,
      },
      summary: {},
      introduction: {
        required,
      },
      segments: {},
      sequence_parent: {
        required: requiredIf(function () {
          return this.isSequencedActivity;
        }),
      },
      settings: {
        3: {
          data: {
            value: {
              required,
            },
          },
        },
        4: {
          data: {
            value: {
              required,
            },
          },
        },
      },
    },
  },

  components: {
    InputAdvancedSegmentSelect,
  },

  mounted() {
    this.clearErrors();
    this.form_reset = JSON.parse(JSON.stringify(this.form));
    this.fetchProjectRoomSetups();

    if (this.isEditActivity || this.isDuplicateActivity) {
      this.pageLoader.addRequiredComponent();
      this.fetchEditActivityInfo();
    } else if (this.isSequencedActivity) {
      this.pageLoader.addRequiredComponent();
      this.fetchParentActivityInfo();
    } else {
      this.prepopulateDates();
      this.fetchProjectSegmentations();
    }
  },

  methods: {
    getEndsOnBefore(end_date) {
      const before_enddate = moment(end_date)
        .subtract(1, "minutes")
        .format("YYYY-MM-DD HH:mm:ss");

      return before_enddate;
    },
    getSystemActivityTypeIcon(systemActivityType) {
      return SystemIconHelper.activityType(systemActivityType);
    },
    fetchParentActivityInfo() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          projectSegments: "with",
        },
      };
      if (
        (this.isEditActivity || this.isDuplicateActivity) &&
        this.activityInfo.sequence_parent != null
      ) {
        payload.activityId = this.activityInfo.sequence_parent;
      } else {
        payload.activityId = this.$route.params.activity;
      }

      this.$store
        .dispatch(POPULATE_PARENT_ACTIVITY_INFO, payload)
        .then(() => {
          this.fetchProjectSegmentations();
          this.prepopulateSequenceParent();
          this.prepopulateParentRoom();
          this.prepopulateDates();

          if (this.isEditActivity || this.isDuplicateActivity) {
            if (
              this.activityInfo.starts_on != this.parentActivityInfo.starts_on
            ) {
              this.sequence_option_id = 2;
            }
          }

          this.pageLoader.setCompleteComponent();
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the parent activity information, please check back later or contact the helpdesk";
        });
    },
    fetchEditActivityInfo() {
      let payload = {
        projectId: this.projectInfo.id,
        activityId: this.$route.params.activity,
        params: {
          activityNotifications: "with",
          projectSegments: "with",
          activitySettings: "with",
          stimulusAssets: "with",
          appendSignedUrls: 1,
        },
      };

      this.$store
        .dispatch(POPULATE_ACTIVITY_INFO, payload)
        .then(() => {
          if (this.activityInfo.sequence_parent != null) {
            this.pageLoader.addRequiredComponent();
            this.fetchParentActivityInfo();
          } else {
            this.prepopulateDates();
            this.fetchProjectSegmentations();
          }

          this.setEditActivityInfo();
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the activity information, please check back later or contact the helpdesk";
        });
    },
    setEditActivityInfo() {
      this.form.published = this.activityInfo.published;
      this.form.featured = this.activityInfo.featured;
      this.form.project_room_id = this.activityInfo.project_room_id;
      this.form.starts_on = this.activityInfo.starts_on;
      this.form.ends_on = this.activityInfo.ends_on;
      this.form.system_social_setting_id =
        this.activityInfo.system_social_setting_id;
      this.form.system_social_bias_id = this.activityInfo.system_social_bias_id;
      this.form.title = this.activityInfo.title;
      this.form.summary = this.activityInfo.summary;
      this.form.introduction = this.activityInfo.introduction;

      if (this.activityInfo.activity_notifications.length > 0) {
        if (
          this.activityInfo.activity_notifications[0].pivot
            .initial_notification != null
        ) {
          this.form.notifications[1].data.initial_notification =
            this.activityInfo.activity_notifications[0].pivot.initial_notification;
          this.selected_notification_period = {
            value: this.form.notifications[1].data.initial_notification,
          };
          this.notification_toggle = 1;
        }

        if (
          this.activityInfo.activity_notifications[0].pivot.keep_repeating !=
          null
        ) {
          this.form.notifications[1].data.keep_repeating =
            this.activityInfo.activity_notifications[0].pivot.keep_repeating;
          this.repeat_notification_toggle = 1;
          this.selected_repeat_option = {
            value: this.form.notifications[1].data.keep_repeating,
          };
          this.form.notifications[1].data.repeat_frequency =
            this.activityInfo.activity_notifications[0].pivot.repeat_frequency;
          this.selected_repeat_notification_period = {
            value: this.form.notifications[1].data.repeat_frequency,
          };
        }
      }

      this.form.segments = [];
      if (this.activityInfo.project_segments.length > 0) {
        this.activityInfo.project_segments.forEach((segment) => {
          let seg = { parent: segment.project_segmentation_id, id: segment.id };
          this.initial_selection.push(seg);
        });
      }
      if (this.activityInfo.activity_settings.length > 0) {
        this.activityInfo.activity_settings.forEach((setting) => {
          let value = parseInt(setting.pivot.value);
          this.form.settings[
            setting.pivot.system_activity_setting_id
          ].data.value = value;
        });
      }
      if (this.isSequencedActivity) {
        this.form.sequence_parent = this.activityInfo.sequence_parent;
      }

      this.fetchSurveyQuestions();
    },
    fetchProjectSegmentations() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          projectSegments: "with",
          paginate: false,
        },
      };
      this.$store
        .dispatch(POPULATE_PROJECT_SEGMENTATION_LIST, payload)
        .then(() => {
          if (this.isSequencedActivity || this.isDuplicateSequencedActivity) {
            if (this.parentActivityInfo.project_segments.length > 0) {
              this.fetchParentSegments();
            } else {
              this.pageLoader.setCompleteComponent();
            }
          } else {
            this.pageLoader.setCompleteComponent();
          }
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the project segmentations list, please check back later or contact the helpdesk";
        });
    },
    fetchParentSegments() {
      let removeSegmentations = [];
      this.projectSegmentations.forEach((segmentation) => {
        let removeSegments = [];
        segmentation.project_segments.forEach((projectSegment) => {
          let segmentSelectedParent = false;
          this.parentActivityInfo.project_segments.forEach((parentSegment) => {
            if (projectSegment.id == parentSegment.id) {
              segmentSelectedParent = true;
            }
          });

          if (segmentSelectedParent == false) {
            var segmentIndex = segmentation.project_segments.findIndex(
              function (segment) {
                return segment.id == projectSegment.id;
              }
            );
            if (segmentIndex > -1) {
              removeSegments.push(segmentIndex);
            }
          }
        });

        for (var i = removeSegments.length - 1; i >= 0; i--)
          segmentation.project_segments.splice(removeSegments[i], 1);

        if (segmentation.project_segments.length == 0) {
          var segmentationIndex = this.projectSegmentations.findIndex(function (
            projectSegmentation
          ) {
            return projectSegmentation.id == segmentation.id;
          });
          if (segmentationIndex > -1) {
            removeSegmentations.push(segmentationIndex);
          }
        }
      });

      for (var i = removeSegmentations.length - 1; i >= 0; i--)
        this.projectSegmentations.splice(removeSegmentations[i], 1);

      this.pageLoader.setCompleteComponent();
    },
    fetchProjectRoomSetups() {
      let payload = {
        projectId: this.projectInfo.id,
        data: {
          params: {
            projectRooms: "with",
          },
        },
      };
      this.$store
        .dispatch(POPULATE_PROJECT_ROOM_SETUP_LIST, payload)
        .then(() => {
          if (this.projectRoomSetupList.length > 0) {
            if (this.projectRoomSetupList[0].project_rooms.length > 0) {
              this.projectRoomSetupList[0].project_rooms.unshift({
                id: null,
                title: "None",
              });
            }
          }
          this.pageLoader.setCompleteComponent();
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the project room setups list, please check back later or contact the helpdesk";
        });
    },
    fetchSurveyQuestions(page = 1) {
      let payload = {
        activityId: this.activityInfo.id,
        params: {
          answerType: "with",
          stimulusAssets: "with",
          sorting: "ASC",
          page: page,
          pageLength: this.page_length,
          paginate: true,
        },
      };

      this.$store
        .dispatch(POPULATE_SURVEY_QUESTION_LIST, payload)
        .then(() => {
          this.surveyQuestionList.forEach((question) => {
            question.stimuli = [];
            question.stimuli_remove = [];
            question.previews = [];
          });
          this.appendSurveyQuestionChoices();
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the survey question list, please check back later or contact the helpdesk";
        })
        .finally(() => {
          this.filtering = false;
        });
    },
    appendSurveyQuestionChoices() {
      let itemsProcessed = 0;
      let itemsNeeded = this.surveyQuestionList.length;

      this.surveyQuestionList.forEach((surveyQuestion) => {
        if (!surveyQuestion.is_page_break) {
          surveyQuestion.choices = [];

          let payload = {
            surveyQuestionId: surveyQuestion.id,
            params: {
              projectSegment: "with",
              stimulusAssets: "with",
              appendSignedUrls: 1,
              paginate: false,
            },
          };

          this.$store
            .dispatch(POPULATE_SURVEY_QUESTION_CHOICE_LIST, payload)
            .then(() => {
              this.surveyQuestionChoiceList.forEach((surveyQuestionChoice) => {
                surveyQuestionChoice.stimuli = [];
                surveyQuestionChoice.previews = [];
                surveyQuestion.choices.push(surveyQuestionChoice);
              });
              itemsProcessed++;
              if (itemsProcessed >= itemsNeeded) {
                this.pageLoader.setCompleteComponent();
              }
            })
            .catch(() => {
              this.error.active = true;
              this.error.message =
                "We're having some issues retrieving the survey question choice list, please check back later or contact the helpdesk";
            });
        } else {
          itemsProcessed++;
        }
      });
    },
    getPayload() {
      let payload = {
        projectId: this.projectInfo.id,
        data: this.form,
      };

      payload.data.questions = this.alignedByPageBreak(this.surveyQuestionList);

      payload.data.questions.forEach((question) => {
        question.previews = [];
        question.choices.forEach((choice) => {
          choice.previews = [];
        });
      });

      if (this.isEditActivity) {
        payload.activityId = this.$route.params.activity;
      }

      if (this.isSequencedActivity) {
        if (this.sequence_option_id == 1) {
          payload.data.starts_on = this.parentActivityInfo.starts_on;
        }
        if (this.parentActivityInfo.ends_on != null) {
          payload.data.ends_on = this.parentActivityInfo.ends_on;
        }

        if (this.form.segments.length == 0) {
          payload.data.segments = [];
          this.parentActivityInfo.project_segments.forEach((segment) => {
            payload.data.segments.push(segment.id);
          });
        }
      }

      // If notifications are toggled off then remove them from the payload
      if (this.notification_toggle == 0) {
        delete payload.data.notifications;
      } else if (this.repeat_notification_toggle == 0) {
        payload.data.notifications[1].data.keep_repeating = null;
        payload.data.notifications[1].data.repeat_frequency = null;
      }

      if (this.isDuplicateActivity) {
        payload.data.stimulus_assets = this.activityInfo.stimulus_assets;
      }

      return payload;
    },
    alignedByPageBreak(list) {
      let page_no = 1;
      let arr = [];
      for (let index = 0; index < list.length; index++) {
        const element = list[index];
        if (element.is_page_break) {
          page_no++;
        } else {
          element.page = page_no;
          arr.push(element);
        }
      }
      return arr;
    },
    onSubmit(sequence_route = null) {
      this.clearErrors();

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.$refs["sequence-modal"].hide();
        return;
      }
      this.submissionStates.submitting = true;

      let payload = this.getPayload();

      this.$store
        .dispatch(
          this.isEditActivity
            ? UPDATE_PROJECT_ACTIVITY
            : CREATE_PROJECT_ACTIVITY,
          payload
        )
        .then((res) => {
          this.makeToast("success");
          if (sequence_route != null) {
            this.clearData();
            this.pageLoader.addRequiredComponent(2);
            this.$router.push({
              name: sequence_route,
              params: { activity: res.id },
            });
            this.$route.params.activity = res.id;
            this.form = JSON.parse(JSON.stringify(this.form_reset));
            this.repeat_notification_toggle = 0;
            this.sequence_option_id = 1;
            this.initial_selection = [];
            this.fetchParentActivityInfo();
            this.$refs["sequence-modal"].hide();
          } else {
            this.$router.push({ name: "project_activities" });
          }
        })
        .catch(() => {
          this.$refs["sequence-modal"].hide();
        })
        .finally(() => {
          this.submissionStates.submitting = false;
          this.$v.form.$reset();
        });
    },
    onSubmitThenSequence() {
      this.$refs["sequence-modal"].show();
    },
    clearData: function () {
      this.$store.dispatch(CLEAR_ACTIVITY_INFO);
      this.$store.dispatch(CLEAR_SURVEY_QUESTION_LIST);
      this.$store.dispatch(CLEAR_SURVEY_QUESTION_CHOICE_LIST);
    },
    makeToast(variant = null) {
      if (this.isEditActivity) {
        this.toast.notify(
          this,
          this.form.title + " " + `${this.$t("TOASTS.EDIT_ACTIVITY.MESSAGE")}`,
          `${this.$t("TOASTS.EDIT_ACTIVITY.TITLE")}`,
          variant
        );
      } else {
        this.toast.notify(
          this,
          this.form.title + " " + `${this.$t("TOASTS.ADD_ACTIVITY.MESSAGE")}`,
          `${this.$t("TOASTS.ADD_ACTIVITY.TITLE")}`,
          variant
        );
      }
    },
    prepopulateDates() {
      if (this.isEditActivity || this.isDuplicateActivity) {
        this.form.starts_on = this.activityInfo.starts_on;
      } else if (this.isSequencedActivity) {
        this.form.starts_on = this.parentActivityInfo.starts_on;
      } else {
        this.form.starts_on = this.projectInfo.starts_on;
      }

      this.form.ends_on = this.getEndsOnBefore(this.projectInfo.ends_on);
    },
    prepopulateSequenceParent() {
      if (this.isEditActivity || this.isDuplicateActivity) {
        this.form.sequence_parent = this.activityInfo.sequence_parent;
      } else {
        this.form.sequence_parent = this.$route.params.activity;
      }
    },
    prepopulateParentRoom() {
      this.form.project_room_id = this.parentActivityInfo.project_room_id;
    },
    clearErrors: function () {
      this.$store.dispatch(CLEAR_ACTIVITY_ERRORS);
      this.$store.dispatch(CLEAR_SURVEY_QUESTION_ERRORS);
      this.$store.dispatch(CLEAR_SURVEY_QUESTION_CHOICE_ERRORS);
    },
    initialNotificationPeriodChanged(value) {
      this.form.notifications[1].data.initial_notification = value;
    },
    notificationRepeatOptionChanged(value) {
      this.form.notifications[1].data.keep_repeating = value;
    },
    repeatNotificationPeriodChanged(value) {
      this.form.notifications[1].data.repeat_frequency = value;
    },
  },

  computed: {
    ...mapGetters([
      "projectInfo",
      "projectSegmentations",
      "projectRoomSetupList",
      "activityErrors",
      "surveyQuestionErrors",
      "activityInfo",
      "parentActivityInfo",
      "systemActivityTypes",
      "surveyQuestionList",
      "surveyQuestionChoiceList",
    ]),
    assetReservationData: function () {
      let data = {
        scope: "activity",
        data: {
          directory: "stimuli",
          projectId: this.projectInfo.id,
          new: 1,
        },
      };
      return data;
    },
    loadingComplete: function () {
      return this.pageLoader.getStatus();
    },
    managementType: function () {
      if (this.isEditActivity) {
        return "update";
      } else if (this.isDuplicateActivity) {
        return "duplicate";
      } else {
        return "post";
      }
    },
    calculatedStatus: function () {
      if (this.managementType == "update" && this.loadingComplete == true) {
        switch (this.activityInfo.system_activity_stage_id) {
          case 1:
            return "draft";
          case 2:
            return "published";
          case 3:
            return "active";
          case 4:
            return "closed";
          default:
            return null;
        }
      } else {
        return null;
      }
    },
    tab2Errors: function () {
      if (
        this.$v.form.published.$anyError ||
        this.$v.form.featured.$anyError ||
        this.$v.form.project_room_id.$anyError ||
        this.$v.form.starts_on.$anyError ||
        this.$v.form.ends_on.$anyError ||
        this.$v.form.notifications.$anyError ||
        this.$v.form.system_social_bias_id.$anyError ||
        this.$v.form.system_social_setting_id.$anyError
      ) {
        return true;
      } else {
        return false;
      }
    },
    tab1Errors: function () {
      if (
        this.$v.form.title.$anyError ||
        this.$v.form.summary.$anyError ||
        this.$v.form.introduction.$anyError
      ) {
        return true;
      } else {
        return false;
      }
    },
    pageType() {
      var routeName = this.$route.name;
      var pageType = routeName.replace("_survey", "");
      return pageType;
    },
    isSequencedActivity: function () {
      return ManageActivityHelper.sequencedActivity(
        this.$route.params.activity,
        this.pageType,
        this.activityInfo.sequence_parent
      );
    },
    sequenceableActivity: function () {
      return ManageActivityHelper.sequenceableActivity(
        this.systemActivityTypes,
        this.form.system_activity_type_id
      );
    },
    isEditActivity: function () {
      return ManageActivityHelper.editingActivity(
        this.$route.params.activity,
        this.pageType
      );
    },
    isDuplicateActivity: function () {
      return ManageActivityHelper.duplicatingActivity(
        this.$route.params.activity,
        this.pageType
      );
    },
    isDuplicateSequencedActivity: function () {
      return ManageActivityHelper.duplicatingSequencedActivity(
        this.isDuplicateActivity,
        this.activityInfo.sequence_parent
      );
    },
    isScheduledActivity: function () {
      return ManageActivityHelper.scheduledActivity(
        this.isSequencedActivity,
        this.sequence_option_id,
        this.isDuplicateSequencedActivity
      );
    },
    isInitialNotificationToggled: function () {
      return ManageActivityHelper.initialNotificationToggled(
        this.notification_toggle
      );
    },
    isRepeatNotificationToggled: function () {
      return ManageActivityHelper.repeatNotificationToggled(
        this.repeat_notification_toggle
      );
    },
    computedValidation: function () {
      return this.$v.form;
    },
    computedTabs: function () {
      if (this.loadingComplete) {
        return TopformConfig.topform(this);
      } else {
        return [];
      }
    },
    computedActions: function () {
      return [
        {
          name: `${this.$t("ACTIVITY.BUTTONS.SAVE_AND_CREATE_SEQUENCE")}`,
          emit: "submit_then_sequence",
        },
      ];
    },
  },
};
</script>
